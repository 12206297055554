import React, { useCallback, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { FaGoogle, FaRegCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import logoBranco from '../../assets/image/LOGO_WEB.png';
import packageJsonInfo from '../../../package.json';

import {
   Container,
   Content,
   Header,
   Footer,
   HeaderIMG,
   Text,
   ButtonGoogle,
   ButtonText,
   CodeAuth,
   CodeHeader,
   CodeContent,
   CodeText,
   ButtonCopy,
} from './styles';

function Main() {
   const [loading, setLoading] = useState(false);
   const [code, setCode] = useState(null);

   const handleSucess = useCallback((response) => {
      toast.success('Autenticação realizada!');
      setCode(response.code);
      setLoading(false);
   }, []);

   const handleError = useCallback(() => {
      toast.error('A autenticação não foi realizada!');
      setCode(null);
      setLoading(false);
   }, []);

   const handleCopy = useCallback(() => {
      toast.success('Chave copiada!', {
         position: 'bottom-right',
         autoClose: 2000,
         style: { backgroundColor: '#504e56' },
      });
      navigator.clipboard.writeText(code);
   }, [code]);

   const handleClick = useCallback((props) => {
      setLoading(true);
      props.onClick();
   }, []);

   return (
      <Container>
         <Content>
            <Header>
               <HeaderIMG src={logoBranco} />
            </Header>
            <Text>Bem-vindo, favor realizar autenticação com uma conta Google.</Text>

            {!code && (
               <GoogleLogin
                  clientId={process.env.REACT_APP_CLIENT_ID}
                  scope="https://www.googleapis.com/auth/drive"
                  buttonText="Fazer Autenticação"
                  onSuccess={handleSucess}
                  onFailure={handleError}
                  accessType="offline"
                  responseType="code"
                  prompt="consent"
                  cookiePolicy="single_host_origin"
                  style={{ backgroundColor: 'red' }}
                  redirectUri={process.env.REACT_APP_REDIRECT_URI}
                  render={(prop) => (
                     <ButtonGoogle onClick={() => handleClick(prop)} disabled={prop.disabled || loading}>
                        {loading ? (
                           <ReactLoading type="bars" />
                        ) : (
                           <>
                              <FaGoogle size="1em" />
                              <ButtonText>FAZER LOGIN</ButtonText>
                           </>
                        )}
                     </ButtonGoogle>
                  )}
               />
            )}

            {code && (
               <CodeAuth>
                  <CodeHeader>Sua chave de acesso:</CodeHeader>
                  <CodeContent>
                     <CodeText>{code}</CodeText>

                     <ButtonCopy title="Copiar" onClick={handleCopy}>
                        <FaRegCopy size="1em" />
                     </ButtonCopy>
                  </CodeContent>
               </CodeAuth>
            )}
         </Content>

         <Footer>{packageJsonInfo.author}</Footer>
      </Container>
   );
}

export default Main;
