import React from 'react';
import { ToastContainer } from 'react-toastify';
import Main from './pages/Main';

import GlobalStyle from './styles/global';
import 'react-toastify/dist/ReactToastify.css';

function App() {
   return (
      <>
         <GlobalStyle />
         <ToastContainer autoClose={4000} />
         <Main />
      </>
   );
}

export default App;
