import styled, { css } from 'styled-components';

export const Container = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;

   height: 100%;
`;

export const Content = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-top: 50px;
`;

export const Footer = styled.span`
   padding-bottom: 10px;
   font-size: 14px;
   color: #f9f9f9;
`;

export const Header = styled.div`
   height: 150px;
   width: 140px;
`;

export const HeaderIMG = styled.img`
   width: 100%;
   height: 100%;
`;

export const Text = styled.span`
   color: #f9f9f9;
   font-size: 16px;
   padding-bottom: 20px;
`;

export const ButtonGoogle = styled.button`
   display: flex;

   justify-content: center;
   align-items: center;

   height: 50px;
   width: 200px;
   background: transparent;
   /* background-color: rgba(255, 255, 255, 0.1); */
   color: #f9f9f9;
   font-size: 16px;
   font-weight: bold;
   border-radius: 5px;
   border: 2px solid #f9f9f9;

   ${(props) =>
      props.disabled
         ? css`
              cursor: not-allowed;
           `
         : css`
              &:hover {
                 background: transparent;
                 background-color: rgba(255, 255, 255, 0.3);
              }
           `}
`;

export const ButtonText = styled.span`
   padding-left: 10px;
`;

export const CodeAuth = styled.div`
   display: flex;
   flex-direction: column;
   width: 70%;
`;

export const CodeContent = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   height: 100px;
   padding: 5px 10px;
   background-color: rgba(255, 255, 255, 0.4);
   border-radius: 5px;
   border: 2px solid #f9f9f9;
`;

export const CodeHeader = styled.div`
   color: #f9f9f9;
   padding-bottom: 5px;
   font-size: 16px;
`;

export const CodeText = styled.div`
   display: flex;
   padding-top: 5px;
   padding-right: 10px;
   word-break: break-word;
   color: #504e56;
`;

export const ButtonCopy = styled.button`
   display: flex;
   justify-content: center;
   align-items: center;
   color: #504e56;
   height: 30px;
   width: 30px;
   background: transparent;
   border: 0px;
   border-radius: 5px;
   &:hover {
      background: transparent;
      background-color: rgba(255, 255, 255, 0.2);
   }
`;
